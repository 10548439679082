<template>
  <div v-if="visibleNoNews && !visible">
    <div class="homepage-main-container homepage-main" style="min-height: 400px;padding-top:40px;">
      <span>Er is geen nieuws gevonden voor de beschikbare Sales Force Accounts</span>
    </div>
  </div>
  <div v-if="!visible && !visibleNoNews">
    <div class="homepage-main-container homepage-main" style="min-height: 400px;padding-top:40px;">
      <span>{{ statusText }}</span>
    </div>
  </div>
  <div v-if="visible">
    <div class="news-select-page">
      <div class="page-top">
        <!--     <div class="logo-container row justify-end">
        <img
          class="logo"
          src="https://www.vindsubsidies.nl/frontend/assets/static/logo.svg"
        />
      </div> -->
        <p class="page-intro row">
          Gebaseerd op het profiel van deze klant, zijn
          {{ currentNewsOptions.length }} nieuwsberichten klaargezet
        </p>
      </div>
    </div>

    <!-- Select news items per customer -->
    <div class="news-select customers-select">
      <NewsDialogPerCustomer />
      <div class="select-page-top">
        <div class="row justify-between fixed-width">
          <div class="column">
            <h3 class="page-heading row">
              {{ currentSalesForceCustomer.name }}
            </h3>
            <div class="page-sub">
              Selecteer de nieuwsberichten voor deze klant
            </div>
          </div>

          <div class="counter" v-if="salesForceCustomersCount - currentCustomersIndex - 1 > 1">
            Nog {{ salesForceCustomersCount - currentCustomersIndex - 1 }} klanten
            te gaan
          </div>
          <div class="counter" v-else-if="salesForceCustomersCount - currentCustomersIndex - 1 == 1">
            Nog {{ salesForceCustomersCount - currentCustomersIndex - 1 }} klant
            te gaan
          </div>
          <div class="counter" v-else>
            Dit is de laatste
          </div>
        </div>
      </div>

      <div class="column">
        <div class="row justify-between">
          <q-btn-group class="btn-group" outline>
            <q-btn no-caps class="btn-vind-dark" @click="selectAllNews()" label="Selecteer alles" />
            <q-btn no-caps class="btn-vind-dark" label="Deselecteer alles" @click="deselectAllNews()" />
          </q-btn-group>

          <q-btn-group v-if="currentCustomersIndex == salesForceCustomersCount - 1" class="btn-group">
            <q-btn no-caps class="btn-vind-dark" label="Vorige item" @click="decreaseCustomersIndex()"
              :disable="currentCustomersIndex == 0 ? true : false" />
            <q-btn no-caps class="btn-vind-dark" label="Finish" @click="goToFinishPageCustomers()" />
          </q-btn-group>
          <q-btn-group v-else outline class="btn-group">
            <q-btn no-caps class="btn-vind-dark" label="Vorige item" @click="decreaseCustomersIndex()"
              :disable="currentCustomersIndex == 0 ? true : false" />
            <q-btn no-caps class="btn-vind-dark" label="Volgende item" @click="increaseCustomersIndex()" :disable="
              currentCustomersIndex == salesForceCustomersCount - 1
                ? true
                : false
            " />
          </q-btn-group>
        </div>
        <div class="container-customers-box">
          <div class="customers-box">
            <q-item-label class="list-header row" header>Nieuws titel</q-item-label>
            <q-tree :nodes="currentNewsOptions" v-model:ticked="tickedNews" node-key="id" tick-strategy="strict"
              default-expand-all v-model:selected="selectedNews" @update:selected="showNewsDetails()" />
          </div>
        </div>
      </div>

      <div class="next-item-group row">
        <!--       <q-btn-group v-if="currentCustomersIndex == salesForceCustomersCount - 1">
        <q-btn
          no-caps
          class="btn-vind-dark"
          label="Vorige item"
          @click="decreaseCustomersIndex()"
          :disable="currentCustomersIndex == 0 ? true : false"
        />
        <q-btn
          no-caps
          class="btn-vind-dark"
          label="Finish"
          @click="goToFinishPageCustomers()"
        />
      </q-btn-group>
      <q-btn-group v-else outline>
        <q-btn
          no-caps
          class="btn-vind-dark"
          label="Vorige item"
          @click="decreaseCustomersIndex()"
          :disable="currentCustomersIndex == 0 ? true : false"
        />
        <q-btn
          no-caps
          class="btn-vind-dark"
          label="Volgende item"
          @click="increaseCustomersIndex()"
          :disable="
            currentCustomersIndex == salesForceCustomersCount - 1 ? true : false
          "
        />
      </q-btn-group> -->
      </div>
    </div>

    <!-- Select customers per news item -->

    <!-- <div class="news-select">
    <div class="select-page-top ">
      <div class="column">
        <h3 class="page-heading" @click="enableNewsDialog()">
          {{ currentNewsItem.title }}
        </h3>
        Please select customers that you want to receive this news item
      </div>
           <q-btn
        class="q-ml-md btn-vind-dark switch-btn"
        no-caps
        @click="switchToCustomerMode"
        >Switch to customer mode</q-btn
      > 
    </div>

    <div class="column">
      <q-btn-group class="justify-end btn-group" outline>
        <q-btn
          no-caps
          class="btn-vind-dark"
          @click="selectAll()"
          label="Select all"
        />
        <q-btn
          no-caps
          class="btn-vind-dark"
          label="Deselect all"
          @click="deselectAll()"
        />
      </q-btn-group>

      <div class="customers-box">
        <q-item-label class="list-header" header>Customer name</q-item-label>
        <q-tree
          :nodes="customersList"
          v-model:ticked="ticked"
          node-key="id"
          tick-strategy="strict"
          default-expand-all
        />
      </div>
    </div>

    <div class="next-item-group row justify-end">
      <q-btn-group v-if="currentIndex == itemsCount - 1">
        <q-btn
          no-caps
          class="btn-vind-dark"
          label="Go to previous news item"
          @click="decreaseIndex()"
          :disable="currentIndex == 0 ? true : false"
        />
        <q-btn
          no-caps
          class="btn-vind-dark"
          label="Finish"
          @click="goToFinishPage()"
        />
      </q-btn-group>
      <q-btn-group v-else class="justify-end" outline>
        <q-btn
          no-caps
          class="btn-vind-dark"
          label="Go to previous news item"
          @click="decreaseIndex()"
          :disable="currentIndex == 0 ? true : false"
        />
        <q-btn
          no-caps
          class="btn-vind-dark"
          label="Go to next news item"
          @click="increaseIndex()"
          :disable="currentIndex == itemsCount - 1 ? true : false"
        />
      </q-btn-group>
    </div>
  </div> -->
    <!-- 
    <NewsSelect
      v-if="isNewsSelectPage"
      :currentIndex="currentIndex"
      :currentNewsItem="currentNewsItem"
    /> -->

    <!--  <NewsSelect v-if="isNewsSelectPage" />
    <CustomersSelect v-else /> -->
    <!--   </div>
 -->
  </div>

  <NewsDialog />
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue';
import store from '../store/index';
import NewsDialog from '../components/news-select-components/NewsDialog.vue';
import { useRouter } from 'vue-router';
import NewsDialogPerCustomer from '../components/news-select-components/NewsDialogPerCustomer.vue';
import salesForceService from '../services/salesForceService';

export default {
  name: 'NewsSelectPage',
  components: {
    NewsDialog,
    NewsDialogPerCustomer,
  },
  setup() {
    onMounted(async () => {
      salesForceService.getAccountsSalesforce().then(() => {
        for (let i = 0; i < store.state.salesForce.selectedNewsForCurrentCustomer.length; i++) {
          tickedNews.value.push(store.state.salesForce.selectedNewsForCurrentCustomer[i]);
        }
        if(store.state.salesForce.accountsList.length != null && store.state.salesForce.accountsList.length > 0)
          visible.value = true; 
        else
          visibleNoNews.value = true;
      });      
    });
    const user = computed({
      get: () => store.state.auth.user,
    });

    // This part is for selecting customers per news item
    const currentNewsItem = computed({
      get: () => store.state.salesForce.currentNewsItem,
    });
    const currentIndex = computed({
      get: () => store.state.salesForce.currentIndex,
    });

    const isNewsSelectPage = computed({
      get: () => store.state.newsSelect.isNewsSelectPage,
    });
    const itemsCount = computed({
      get: () => store.state.salesForce.newsItemsCount,
    });
    const decreaseIndex = () => {
      store.commit('salesForce/updateCustomersForNewsItem', ticked.value);
      store.commit('salesForce/decreaseIndex');
      ticked.value = [];
    };

    const increaseIndex = () => {
      store.commit('salesForce/updateCustomersForNewsItem', ticked.value);
      store.commit('salesForce/increaseIndex');
      ticked.value = [];
    };

    const enableNewsDialog = () => {
      store.commit('salesForce/enableNewsModal');
    };
    const router = useRouter();

    const goToFinishPage = () => {
      store.commit('salesForce/updateCustomersForNewsItem', ticked.value);
      ticked.value = [];

      store.commit('salesForce/setIndex', 0);
      router.push({
        name: 'NewsSelectFinal',
      });
    };

    const customerTags = computed({
      get: () => store.state.salesForce.accountsList.customerTags,
    });

    const customersList = computed({
      get: () => store.state.salesForce.customersList,
    });

    const ticked = ref([]);
    const visible = ref(false);
    const visibleNoNews = ref(false);
    const statusText = ref('Checking SalesForce status');

    const deselectAll = () => {
      ticked.value = [];
    };

    const selectAll = () => {
      for (let i = 0; i < customersList.value.length; i++)
        ticked.value.push(customersList.value[i].id);
    };

    // This part is for selecting news items per customer
    const tickedNews = ref([]);

    const accountsList = computed({
      get: () => store.state.salesForce.accountsList,
    });

    const salesForceCustomersList = computed({
      get: () => store.state.salesForce.salesForceCustomersList,
    });

    const salesForceCustomersCount = computed({
      get: () => store.state.salesForce.salesForceCustomersCount,
    });

    const currentSalesForceCustomer = computed({
      get: () => store.state.salesForce.currentSalesForceCustomer,
    });

    const currentCustomersIndex = computed({
      get: () => store.state.salesForce.currentCustomersIndex,
    });

    const serverData = computed({
      get: () => store.state.salesForce.newsItemsPerCustomerListForServer,
    });

    const decreaseCustomersIndex = () => {
      store.commit('salesForce/updateNewsForCustomer', tickedNews.value);

      store.commit('salesForce/decreaseCustomerIndex');
      store.commit('salesForce/updateNewsForCurrentCustomer');
      tickedNews.value = store.state.salesForce.selectedNewsForCurrentCustomer;
      store.commit('salesForce/createNewsItemsPerCustomerListForServer');
      salesForceService.postNewsSelection(serverData.value);
    };

    const increaseCustomersIndex = () => {
      store.commit('salesForce/updateNewsForCustomer', tickedNews.value);

      store.commit('salesForce/increaseCustomerIndex');
      store.commit('salesForce/updateNewsForCurrentCustomer');
      tickedNews.value = store.state.salesForce.selectedNewsForCurrentCustomer;
      store.commit('salesForce/createNewsItemsPerCustomerListForServer');
      salesForceService.postNewsSelection(serverData.value);
    };

    const currentNewsOptions = computed({
      get: () => store.state.salesForce.currentNewsOptions,
    });

    const deselectAllNews = () => {
      tickedNews.value = [];
    };

    const selectAllNews = () => {
      for (let i = 0; i < currentNewsOptions.value.length; i++)
        tickedNews.value.push(currentNewsOptions.value[i].id);
    };

    const selectedNewsPerCustomer = computed({
      get: () => store.state.salesForce.selectedNewsPerCustomer,
    });

    const goToFinishPageCustomers = () => {
      store.commit('salesForce/updateNewsForCustomer', tickedNews.value);

      store.commit('salesForce/setCustomersIndex', 0);
      store.commit('salesForce/createNewsItemsPerCustomerListForServer');
      router.push({
        name: 'NewsForCustomersFinal',
      });
    };

    const showNewsDetails = () => {
      if (selectedNews.value != null) {
        store.commit(
          'salesForce/updateCurrentNewsItemPerCustomer',
          selectedNews.value
        );
        store.commit('salesForce/enableNewsModalPerCustomer');
      }
    };

    const selectedNews = ref(null);

    watch(
      () => selectedNews.value,
      async (next, prev) => {
        if (prev && prev != null && next == null) {
          store.commit('salesForce/updateCurrentNewsItemPerCustomer', prev);
          store.commit('salesForce/enableNewsModalPerCustomer');
        }
      }
    );

    return {
      // Select news items per customer
      selectedNews,
      showNewsDetails,
      goToFinishPageCustomers,
      selectedNewsPerCustomer,
      accountsList,
      salesForceCustomersList,
      salesForceCustomersCount,
      currentSalesForceCustomer,
      currentCustomersIndex,
      increaseCustomersIndex,
      decreaseCustomersIndex,
      currentNewsOptions,
      tickedNews,
      selectAllNews,
      deselectAllNews,
      // Select customers per news item
      selectAll,
      deselectAll,
      ticked,
      customersList,
      customerTags,
      goToFinishPage,
      enableNewsDialog,
      increaseIndex,
      decreaseIndex,
      currentNewsItem,
      currentIndex,
      itemsCount,
      user,
      isNewsSelectPage,
      visible,
      visibleNoNews,
      statusText,
    };
  },
};
</script>
