<template>
  <main class="main-tag">3
    <div class="login-page" id="login-page" style="display: none"
      :class="isVind ? 'login-page-default' : 'login-page-portals'">
      <div class="background-img" v-if="!isVind"></div>

      <div class="q-pa-md" v-if="!isVind">
        <ErrorModal v-if="hasModalError" />
        <q-form @submit="onSubmit" @reset="onReset" class="q-gutter-md login-form row">
          <div>
            <div v-if="!isVind" class="column">
              <h4 class="q-mb-md welcome-heading-text">
                Welkom op Vindsubsidies loket {{ customerPortalSignatureName }}
              </h4>
              <p class="sub-welcome-text">
                Vind relevante subsidies door je te registeren
              </p>
            </div>

            <h4 v-else> {{ t('login.login') }}</h4>
            <div class="login-field-wrap">
              <q-input v-model="user.email" bg-color="white" :label="t('login.e-mail_address')" dense borderless :rules="[
        (val) => !!val || t('login.email_validation'),
        isValidEmail,
      ]" />
              <div class="password-fields q-my-md">
                <q-input type="password" bg-color="white" v-model="user.password" :label="t('login.password')"
                  lazy-rules borderless dense :rules="[
        (val) =>
          (val && val.length > 5) || t('login.password_validation'),
      ]" />
              </div>
              <div class="password-options">
                <q-checkbox v-model="user.right" :label="t('login.remember')" />
                <router-link :to="{ name: 'ResetPassword' }" class="navmenu-link">
                  <p class="q-mt-md q-ml-md" style="color: #131879; text-decoration: underline">
                    {{ t('login.forgot_password') }}
                  </p>
                </router-link>
              </div>
            </div>
            <div>
              <q-btn :label="t('login.log_in')" type="submit" no-caps class="bold-font orange-btn-filled" />
            </div>
            <div class="register-btn" v-if="!isVind">
              <p><strong> {{ t('login.create_account') }} </strong></p>
              <q-btn no-caps class="bold-font orange-btn q-mb-md" style="max-width: 124px; text-decoration: none"
                :label="t('login.register')" :to="{ name: 'Register' }" />
            </div>
          </div>
          <!-- according to redesign this div is hidden 
          
          <div class="news-list-login">
          <p class="login-warning" v-if="hasUserClickedOnNews">
            Log eerst in of meld je aan
          </p>
          <p class="news-heading row">Laatste nieuws</p>
          <div
            v-for="news in newsList.results"
            :key="news.id"
            class="news-title q-mt-sm row"
            @click="updateHasClickedOnNews()"
          >
            <div class="title-and-date column">
              <p class="date">{{ formatDate(news.date) }}</p>
              <p class="title">{{ news.title }}</p>
            </div>
          </div>
        </div> -->
        </q-form>

        <!--    <div class="row justify-around q-pt-md" v-if="!isVind">
        <q-btn
          no-caps
          color="#0a1f8f"
          style="background:#14157e; max-width: 400px; text-decoration: none;"
          label="Nog geen account? Meld je hier aan"
          :to="{ name: 'Register' }"
        />
      </div> -->
      </div>
      <div class="q-pa-md" v-else>
        <ErrorModal v-if="hasModalError" />
        <q-form @submit="onSubmit" @reset="onReset" class="q-gutter-md login-form-center row">
          <div>
            <h4>{{ t('login.login') }}</h4>
            <q-input v-model="user.email" borderless :placeholder="t('login.e-mail')" bg-color="white" />
            <div class="password-fields q-mt-md">
              <q-input borderless type="password" v-model="user.password" :placeholder="t('login.password')"
                bg-color="white" lazy-rules :rules="[
        (val) =>
          (val && val.length > 5) || t('login.password_validation'),
      ]" />
              <div class="password-options">
                <q-checkbox v-model="user.right" :label="t('login.remember')" />
                <router-link :to="{ name: 'ResetPassword' }" class="navmenu-link">
                  <q-btn no-caps flat :label="t('login.forgot_password')" class="forgot-password" />
                </router-link>
              </div>
            </div>
            <div>
              <q-btn :label="t('login.log_in')" type="submit" class="login-btn bold-font" no-caps color="#0a1f8f" />
            </div>
          </div>
        </q-form>
        <div class="row justify-around q-pt-md" v-if="!isVind">
          <q-btn no-caps color="#0a1f8f" style="background: #14157e; max-width: 400px; text-decoration: none"
            :label="t('login.sign_up_question')" :to="{ name: 'Register' }" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { computed, reactive, onMounted } from 'vue';
import store from '../store/index.js';
import { useRouter } from 'vue-router';
import ErrorModal from './ErrorModal.vue';
import authService from '../services/auth.service.js';
import newsService from '../services/news.service';
import moment from 'moment';
import UserService from '../services/userprofile.service';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    ErrorModal,
  },
  setup() {
    const user = reactive({
      email: null,
      password: null,
      right: false,
    });

    const { t } = useI18n();

    onMounted(async () => {
      authService.getEnvironment();
      await authService.getPortalInfo();
      await newsService.getNewsForLoginPage();
    });

    const newsList = computed({
      get: () => store.state.news.latestNewsLoginPage,
    });

    const router = useRouter();

    const isLoggedIn = computed({
      get: () => store.state.auth.isLoggedIn,
    });
    const hasModalError = computed({
      get: () => store.state.auth.hasModalError,
    });

    const hasComeFromNewsletter = computed({
      get: () => store.state.user.hasComeFromNewsletter,
    });

    const newsletterId = computed({
      get: () => store.state.user.newsLetterId,
    });

    const updateHasClickedOnNews = () => {
      store.commit('auth/updateHasClickedNewsOnLoginPage', true);
    };

    if (isLoggedIn.value == true) {
      const localStorageUser = JSON.parse(localStorage.getItem('user'));
      let token = JSON.parse(
        Buffer.from(localStorageUser.token.split('.')[1], 'base64')
      );

      if (token.env == null) token.env = 0;

      store.commit('auth/setEnvironment', Number.parseInt(token.env));
      store.commit('auth/setCustid', Number.parseInt(token.custid));
      if (hasComeFromNewsletter.value) {
        router.push({
          name: 'NewsDetails',
          params: { newsId: newsletterId.value },
        });
        store.commit('user/setHasComeFromNewsletter', false);
      } else {
        router.push({
          name: 'SearchGrants',
        });
      }
    }

    const onSubmit = () => {
      store
        .dispatch('auth/login', user)
        .then(() => {
          const localStorageUser = JSON.parse(localStorage.getItem('user'));
          let token = JSON.parse(
            Buffer.from(localStorageUser.token.split('.')[1], 'base64')
          );

          if (token.env == null) token.env = 0;

          store.commit('auth/setEnvironment', Number.parseInt(token.env));
          store.commit('auth/setCustid', Number.parseInt(token.custid));
          if (
            isVind.value &&
            !localStorageUser.hasCompletedProfileSetup
          ) {
            router.push({
              name: 'UserProfile',
              params: { tab: 'newsletter' },
            });
            store.commit('user/updateHasBeenRedirectedToProfile', true);
            store.commit('user/setHasConfirmedLeave', false);
          } else if (hasComeFromNewsletter.value) {
            router.push({
              name: 'NewsDetails',
              params: { newsId: newsletterId.value },
            });

            store.commit('user/setHasComeFromNewsletter', true);
          } else if (hasUserClickedOnNews.value) {
            router.push({
              name: 'News',
            });
          } else if (isVind.value) {
            router.push({
              name: 'SearchGrants',
            });
          } else if (!isVind.value) {
            router.push({
              name: 'PortalSearchGrants',
            });
            UserService.getFolders();
          }

          if (Number.parseInt(token.custid) != 2) // if customer is not Vind, check language
            authService.getAppropriateLanguageBasedBaseUrl(localStorageUser.id);
        })
        .catch((error) => {
          if (error)
            if (error.response.data.errors != null) {
              store.commit(
                'auth/updateModalError',
                error.response.data.errors.Email[0]
              );
            } else {
              store.commit('auth/updateModalError', error.response.data);
            }
          store.commit('auth/enableModalError');
        });
    };

    const hasUserClickedOnNews = computed({
      get: () => store.state.auth.hasClickedNewsOnLoginPage,
    });

    const formatDate = (stringDate) => {
      const dateN = new Date(stringDate);
      return moment(dateN).format('DD-MM-YYYY');
    };

    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    const customerPortalSignatureName = computed({
      get: () => store.state.auth.customer.customerPortalSignatureName,
    });

    return {
      t,
      customerPortalSignatureName,
      formatDate,
      hasUserClickedOnNews,
      updateHasClickedOnNews,
      newsList,
      newsletterId,
      hasComeFromNewsletter,
      onSubmit,
      router,
      isLoggedIn,
      hasModalError,
      user,
      isVind,
      isValidEmail(val) {
        const emailPattern =
          /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(val) || t('common.invalid_email_address');
      },
      onReset() {
        user.email.value = null;
        user.password.value = null;
      },
    };
  },
};
</script>
