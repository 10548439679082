<template>
  <ErrorModal />
  <SaveSearch v-if="showSave === true" :showSave="showSave" />
  <SharePage />
  <DownloadPage />
  <DownloadSchemeButton />

  <div class="spinner-page" v-if="isPageLoading || isSideBarLoading">
    <q-spinner class="spinner" color="primary" size="3em" />
  </div>
  <div
    v-else
    class="grants-container"
    :class="isContrast != 'false' && isContrast != false ? 'high-contrast' : ''"
  >
    <q-layout>
      <div class="row justify-between heading-container">
        <div v-if="isDisplayDownloadMessage" class="download-finished row">
          <i class="las la-2x la-check-circle"></i>
          <span class="download-text"> {{ t('search_schemes.download_successful') }} </span>
        </div>
      </div>
      <q-btn @click="drawerLeft = true" class="drawer-btn">
        <q-icon size="18px" name="fa-solid fa-sliders"></q-icon>
      </q-btn>

      <q-drawer
        v-model="drawerLeft"
        elevated
        class="bg-primary text-white drawer"
      >
        <SideBarSearch class="drawer-sidebar" ref="sideBar" />

        <div
          class="absolute drawer-close-container"
          style="top: 50%; right: -34px; z-index: 200"
        >
          <q-btn
            dense
            class="drawer-close-btn"
            round
            unelevated
            color="accent"
            icon="close"
            @click="drawerLeft = false"
          ></q-btn>
        </div>
      </q-drawer>
      <div class="grants-search-main">
        <SideBarSearch class="sidebar-main" ref="sideBar" />
        <div class="grant-cards-container">
          <div class="bigscreen-topmenu">
            <div class="row searchpage-top">
              <div class="column" stlye="width: 60vw">
                <span class="curr-nav row">{{ t('navbar.search_schemes') }}</span>
                <div class="row justify-end tour" v-if="isVind">
                  <q-btn class="onboarding-icon" no-caps @click="startTour()"
                    >{{ t('search_schemes.start_tour') }}</q-btn
                  >
                </div>

                <div class="row justify-between heading-top-row">
                  <h3 class="searchpage-heading bold-font">{{ t('navbar.search_schemes') }}</h3>

                  <div class="savebtn-container bold-font" v-if="isVind">
                    <q-btn
                      class="save-btn outline flat"
                      @click="enableSave()"
                      id="v-step-12"
                      :label="$q.screen.gt.xs ? t('search_schemes.save_search') : t('search_schemes.save')"
                    >
                      <q-icon
                        left="true"
                        name="fa-solid fa-floppy-disk"
                        class="q-mr-sm"
                        size="18px"
                      ></q-icon>
                    </q-btn>
                  </div>
                </div>
              </div>
            </div>
            <SearchBar id="v-step-1" />
            <div class="pagination top-pag">
              <q-pagination
                class="pagination-item"
                v-model="currentPage"
                :max="maxPages"
                direction-links
                :max-pages="6"
                @click="goToPage(currentPage)"
                color="grey"
                active-color="green-7"
                text-color="white"
                size="13px"
              ></q-pagination>
            </div>
            <div class="icons-and-sorting">
              <div class="sort-dropdown">
                <div class="row">
                  <p class="selection-complete q-mt-sm">{{ t('search_schemes.sort_by') }}</p>
                  <q-select
                    class="sort-select"
                    dense
                    v-model="selectedSort"
                    input-debounce="0"
                    :options="sortOptions"
                    behavior="menu"
                    bg-color="white"
                    outlined
                    rounded
                  />
                </div>
              </div>

              <div class="row">
                <div class="row action-icons-column">
                  <span class="selection-complete"> {{ t('search_schemes.complete_selection') }} </span>
                  <div class="row icons-list q-mr-md" v-if="isVind">
                    <q-icon
                      name="fa-solid fa-floppy-disk"
                      class="action-icon q-ml-sm"
                      @click="enableSaveScheme"
                      id="v-step-4"
                      size="18px"
                    >
                      <q-tooltip> {{ t('search_schemes.save') }} </q-tooltip></q-icon
                    >
                    <q-icon
                      name="fa-solid fa-share-nodes"
                      class="action-icon q-ml-sm"
                      @click="sharePage()"
                      id="v-step-5"
                      size="18px"
                    >
                      <q-tooltip> {{ t('search_schemes.share') }} </q-tooltip></q-icon
                    >
                    <q-icon
                      name="fa-solid fa-download"
                      class="action-icon q-ml-sm"
                      @click="downloadPage()"
                      id="v-step-6"
                      size="18px"
                    >
                      <q-tooltip> {{ t('search_schemes.download') }} </q-tooltip></q-icon
                    >
                  </div>
                  <ShareScheme v-if="isVind" />
                  <SaveScheme v-if="isVind" />

                  <DownloadScheme v-if="isVind" />
                  <q-btn
                    v-if="numberOfSelectedResults > 0 && isVind"
                    rounded
                    flat
                    class="counter-btn q-mr-md"
                    >{{ t('search_schemes.selected_uppercase') + ' ' }} {{ numberOfSelectedResults }}</q-btn
                  >
                  <div class="selection-complete" v-if="isVind">
                    <span class="select-all q-mr-sm">{{ t('search_schemes.select_all') }}</span>
                    <q-checkbox
                      v-model="selectAllSchemes"
                      @update:model-value="selectAll()"
                      class="select-all-checkbox"
                      dense
                    ></q-checkbox>
                  </div>
                </div>
                <!--   <ShareScheme v-if="isVind" />
            <SaveScheme v-if="isVind" />
            <DownloadScheme v-if="isVind" /> -->
              </div>
            </div>
          </div>

          <div class="small-screen-topmenu">
            <div class="row searchpage-top">
              <div class="column" stlye="width: 60vw">
                <span class="curr-nav row">{{ t('navbar.search_schemes') }}</span>
                <div class="row justify-end tour" v-if="isVind">
                  <q-btn class="onboarding-icon" no-caps @click="startTour()"
                    >{{ t('search_schemes.start_tour') }}</q-btn
                  >
                </div>

                <div class="row justify-between heading-top-row">
                  <h3 class="searchpage-heading bold-font">{{ t('navbar.search_schemes') }}</h3>
                </div>
              </div>
            </div>
            <SearchBar id="v-step-1" />
            <div class="row sort-row">
              <div class="sort-dropdown">
                <div class="row">
                  <q-select
                    class="sort-select"
                    dense
                    v-model="selectedSort"
                    input-debounce="0"
                    :options="sortOptions"
                    behavior="menu"
                    bg-color="white"
                    outlined
                    rounded
                  />
                </div>
              </div>
              <div class="savebtn-container bold-font" v-if="isVind">
                <q-btn
                  class="save-btn outline flat"
                  @click="enableSave()"
                  id="v-step-12"
                  :label="$q.screen.gt.sm ?  t('search_schemes.save_search') : t('search_schemes.save')"
                >
                  <q-icon
                    left="true"
                    name="fa-solid fa-floppy-disk"
                    class="q-mr-sm"
                    size="18px"
                  ></q-icon>
                </q-btn>
              </div>
            </div>
            <div class="pagination top-pag">
              <q-pagination
                class="pagination-item"
                v-model="currentPage"
                :max="maxPages"
                direction-links
                :max-pages="6"
                @click="goToPage(currentPage)"
                color="grey"
                active-color="green-7"
                text-color="white"
                size="13px"
              ></q-pagination>
            </div>
            <div class="icons-and-sorting">
              <ShareScheme v-if="isVind" />
              <SaveScheme v-if="isVind" />

              <DownloadScheme v-if="isVind" />
            </div>
          </div>

          <div v-if="grants.length == 0 && isTourActive" class="q-mt-lg">
            <GrantCard
              :title="'Subsidieregeling praktijkleren'"
              :id="1000"
              :introduction="dummyGrant.introduction"
              :class="card"
              :grant="dummyGrant"
              class="v-step-2"
              :isDummy="true"
            />
          </div>

          <div
            class="cards"
            :class="areFiltersChanged ? 'opacity-low' : 'opacity-normal'"
          >
            <GrantCard
              v-for="(grant, idx) in grants"
              :key="grant.id"
              :title="grant.name"
              :grant="grant"
              :id="grant.id"
              :introduction="grant.introduction"
              class="card"
              :class="{ 'v-step-2': idx == 0 }"
              :matchingPercentage="getSchemeMatchingPercentage(grant.score)"
            />
            <div class="pagination pagination-bottom">
              <q-pagination
                class="pagination-item"
                v-model="currentPage"
                :max="maxPages"
                direction-links
                :max-pages="6"
                @click="goToPage(currentPage)"
                color="grey"
                active-color="green-7"
                text-color="white"
                size="13px"
              ></q-pagination>
            </div>
          </div>
        </div>
        <q-dialog v-model="showWarning" class="profile-popup">
          <q-card>
            <q-card-section class="row items-center">
              <!--   <i class="las la-2x la-exclamation-circle"></i> -->
              <q-icon
                name="info"
                color="indigo-10"
                size="32px"
                class="popup-icon"
              ></q-icon>
              <span class="q-ml-sm bold-font popup-text">
                {{ t('search_schemes.select_at_least_one_item') }}
              </span>
            </q-card-section>

            <q-card-actions align="right">
              <q-btn
                :label="t('common.ok')"
                class="orange-btn bold-font q-mr-md q-mb-sm"
                style="width: 60px"
                v-close-popup
              />
            </q-card-actions>
          </q-card>
        </q-dialog>

        <q-dialog v-model="showSavedInfoDialog">
          <q-card>
            <q-card-section class="row items-center">
              <q-icon
                class="info-icon-searchgrants"
                size="28px"
                name="fa-solid fa-circle-info"
                @click="hideScheme(schemeId)"
              >
              </q-icon>
              <span class="q-ml-sm">
                {{ t('search_schemes.saved_item_notification') }} &nbsp; </span
              ><a href="/profile/savedItemsSchemes">{{ t('search_schemes.saved_items') }}</a>
            </q-card-section>
            <q-card-section style="padding-top: 0; padding-left: 12px">
              <q-toggle
                v-model="receiveAlertForSavedItems"
                :label="t('search_schemes.receive_alerts_for_saved_items_question')"
              />
            </q-card-section>

            <q-card-actions align="right">
              <q-btn
                flat
                class="orange-btn-filled"
                color="white"
                :label="t('common.ok')"
                no-caps
                @click="disableShowSavedInfoDialog()"
              />
            </q-card-actions>
          </q-card>
        </q-dialog>

        <q-dialog v-model="showSavedSearchInfoDialog">
          <q-card>
            <q-card-section class="row items-center">
              <q-icon
                class="info-icon-searchgrants"
                size="28px"
                name="fa-solid fa-circle-info"
                @click="hideScheme(schemeId)"
              >
              </q-icon>

              <span class="q-ml-sm">
                {{ t('search_schemes.saved_search_notification') }} &nbsp; </span
              ><a href="/profile/savedItemsSchemes">{{ t('search_schemes.saved_items') }}</a>
            </q-card-section>
            <q-card-section style="padding-top: 0; padding-left: 12px">
              <q-toggle
                v-model="receiveAlertForSavedSearch"
                :label="t('search_schemes.receive_alerts_for_saved_search_question')"
              />
            </q-card-section>

            <q-card-actions align="right">
              <q-btn
                flat
                class="orange-btn-filled"
                color="white"
                no-caps
                :label="t('common.ok')"
                @click="disableShowSavedSearchInfoDialog()"
              />
            </q-card-actions>
          </q-card>
        </q-dialog>

        <q-dialog v-model="showHiddenInfoDialog">
          <q-card>
            <q-card-section class="row items-center">
              <i class="las la-2x la-info-circle"></i>
              <span class="q-ml-sm">
                {{ t('search_schemes.hidden_items_hint') }} &nbsp; </span
              ><a href="/profile/hiddenSchemes">{{ t('search_schemes.profile') }}</a>
            </q-card-section>
            <q-card-section>
              <q-checkbox
                dense
                size="sm"
                v-model="hideHiddenItemsDialog"
                :label="t('search_schemes.do_not_show_this_message_again')"
                class="checkbox"
              />
            </q-card-section>

            <q-card-actions align="right">
              <q-btn
                outline
                :label="t('common.ok')"
                color="primary"
                @click="disableShowHiddenInfoDialog()"
              />
            </q-card-actions>
          </q-card>
        </q-dialog>
      </div>
      <div class="circle-container">
        <div id="pagetop" class="top-scroll" v-if="scY > 300">
          <i class="las la-2x la-angle-up up-icon" @click="toTop">
            <q-tooltip self="center left" :offset="[10, 10]"
              >{{ t('search_schemes.upwards') }}</q-tooltip
            >
          </i>
        </div>
      </div>

      <div class="circle-container">
        <q-btn
          v-if="numberOfSelectedResults >= 0 && isVind"
          @click="(seamless = true), toTop"
          flat
          no-caps
          id="button-selected"
          class="q-mr-md"
        >
          {{ numberOfSelectedResults }} {{ ' ' + t('search_schemes.selected') }}</q-btn
        >
        <q-dialog
          class="selected_scheme_drawer justify-content-end"
          v-model="seamless"
          position="bottom"
        >
          <div class="q-mr-xl" style="text-align: end; width: 100%">
            <q-btn
              v-if="numberOfSelectedResults >= 0 && isVind"
              no-caps
              unelevated
              flat
              class="q-py-xs"
              style="background-color: #fff; color: #191d96"
            >
              {{ numberOfSelectedResults }} {{ ' ' + t('search_schemes.selected') }}</q-btn
            >
          </div>

          <q-card class="q-pt-md" style="width: 100vw; height: 200px">
            <q-btn
              style="float: right"
              flat
              round
              icon="close"
              v-close-popup
            ></q-btn>
            <q-card-section class="column items-center no-wrap">
              <p class="title">{{ t('search_schemes.complete_selection') }}</p>
              <q-btn
                class="button-card q-mb-md"
                @click="enableSaveScheme"
                outline
                rounded
                no-caps
              >
                <q-icon
                  class="action-icon q-mr-xs"
                  name="fa-solid fa-floppy-disk"
                  size="14px"
                >
                </q-icon>
                {{ t('search_schemes.save') }}
              </q-btn>
              <q-btn
                outline
                rounded
                no-caps
                @click="sharePage()"
                class="button-card"
              >
                <q-icon
                  class="action-icon q-mr-xs"
                  size="14px"
                  name="fa-solid fa-share-nodes"
                >
                </q-icon>
                  {{ t('search_schemes.share') }}
              </q-btn>
              <ShareScheme v-if="isVind" />
              <SaveScheme v-if="isVind" />
            </q-card-section>
          </q-card>
        </q-dialog>
      </div>
    </q-layout>
  </div>
  <v-tour name="myTour" ref="tour" :steps="steps"></v-tour>
</template>

<script>
import GrantCard from "../components/GrantCard.vue";
import SideBarSearch from "../components/search-components/SidebarSearch.vue";
import { mapGetters, mapState } from "vuex";
import { onMounted, computed, ref, watch, onBeforeMount } from "vue";
import store from "../store/index.js";
import router from "@/router";
import GrantsService from "../services/grants.service";
import SearchBar from "../components/search-components/SearchBar.vue";
import SaveSearch from "../components/search-components/SaveSearch.vue";
import ErrorModal from "../components/userProfile-components/ErrorModal.vue";
import ShareScheme from "../components/share-components/ShareScheme.vue";
import SaveScheme from "../components/SaveScheme.vue";
import SharePage from "../components/share-components/SharePage.vue";
import FiltersService from "../services/filters.service";
import SchemeUtils from "../utils/schemes";
import DownloadPage from "../components/share-components/DownloadPage";
import DownloadScheme from "../components/share-components/DownloadScheme.vue";
import UserService from "../services/userprofile.service";
import DownloadSchemeButton from "../components/share-components/DownloadSchemeButton";
import organisationsService from "../services/organisations.service";
import { useI18n } from 'vue-i18n';
import { loadMessages } from '../i18n/index';

export default {
  name: "SearchGrants",
  components: {
    GrantCard,
    SideBarSearch,
    SearchBar,
    SaveSearch,
    ErrorModal,
    ShareScheme,
    SaveScheme,
    SharePage,
    DownloadPage,
    DownloadScheme,
    DownloadSchemeButton,
  },

  setup() {
    const isApplyingFilters = computed({
      get: () => store.state.user.isApplyingFilters,
    });
    const { t, locale } = useI18n();

    onBeforeMount(() => {
      if (!isVind.value) {
        router.push({
          name: "PortalSearchGrants",
        });
      }
    });

    onMounted(async () => {
      await loadMessages(locale.value);
      store.commit("advancedSearch/updateSelectedSort", t('search_schemes.relevance'));
      UserService.getProfileConfiguration();
      UserService.getFavoriteAudienceTags();
      UserService.getFavoritePurposesTags();
      //This is just hidden for now, don't remove it:
      //UserService.getFavoriteIndustriesTags();
      UserService.getFavoriteLocationsTags();
      await FiltersService.getDefaultFilters(locale.value);
      store.commit("advancedSearch/createStatusesString");
      await organisationSchemeSearch();
      window.addEventListener("scroll", handleScroll);
      await SchemeUtils.searchSchemes(locale);
      window.scrollTo(0, 0);
    });

    const sortOptionsKeys = ['search_schemes.date', 'search_schemes.relevance', 'search_schemes.scope_in_sort'];

    // Map over the original array and apply translation function
    const sortOptions = computed(() => {
      return sortOptionsKeys.map(option => t(option));
    });

    const organisationSchemeSearch = async () => {
      if (localStorage.getItem("schemeOrganisationClicked") == "true") {
        await organisationsService.getOrganisationAsync(
          localStorage.getItem("schemeOrganisation")
        );
      }
    };

    const selectedSort = computed({
      get: () => store.state.advancedSearch.selectedSort,
      set: async (value) => {
        store.commit("advancedSearch/updateSelectedSort", value);
        await SchemeUtils.searchSchemes(locale);
      },
    });

    const showWarning = ref(false);

    const maxScoreOnCurrentPage = computed({
      get: () => store.state.advancedSearch.maxScoreOnCurrentPage,
    });

    const maxScore = computed({
      get: () => store.state.advancedSearch.maxScore,
    });

    // const getSchemeMatchingPercentage = (schemeScore) => {
    //   var percentage = (schemeScore * 100) / maxScoreOnCurrentPage.value;
    //   return percentage.toFixed();
    // };

    const getSchemeMatchingPercentage = (schemeScore) => {
      var percentage = (schemeScore * 100) / maxScore.value;
      return percentage.toFixed();
    };

    const maxPages = computed({
      get: () => store.state.advancedSearch.pageCount,
    });

    const grants = computed({
      get: () => store.state.grantItems.grants,
    });

    const organisationsSchemes = computed({
      get: () => store.state.grantItems.organisationGrants,
    });

    const shortDesc = computed({
      get: () => store.state.grantItems.grants.description,
    });

    const pagesCount = computed({
      get: () => store.state.grantItems.grants.totalRecords,
    });

    const resultsCount = computed({
      get: () => store.state.advancedSearch.numberOfResults,
    });

    const logStatus = computed({
      get: () => store.state.user.isLoggedIn,
    });

    const searchText = computed({
      get: () => store.state.advancedSearch.searchText,
    });

    const audiences = computed({
      get: () =>
        store.state.advancedSearch.selectedAudiences != ""
          ? store.state.advancedSearch.selectedAudiences
          : sessionStorage.getItem("selectedAudiences"),
    });
    const locations = computed({
      get: () =>
        store.state.advancedSearch.selectedLocations != ""
          ? store.state.advancedSearch.selectedLocations
          : sessionStorage.getItem("selectedLocations"),
    });
    //This is just hidden for now, don't remove it:
    /*
    const industries = computed({
      get: () => store.state.advancedSearch.selectedIndustries,
    });
    */
    const purposes = computed({
      get: () =>
        store.state.advancedSearch.selectedPurposes != ""
          ? store.state.advancedSearch.selectedPurposes
          : sessionStorage.getItem("selectedPurposes"),
    });
    const schemeTypes = computed({
      get: () =>
        store.state.advancedSearch.selectedSchemeTypes != ""
          ? store.state.advancedSearch.selectedSchemeTypes
          : sessionStorage.getItem("selectedSchemeTypes"),
    });
    const projectTypes = computed({
      get: () => store.state.advancedSearch.selectedProjectTypes,
    });

    const smeString = computed({
      get: () => store.state.advancedFilters.smeString,
    });
    const applicationTermsString = computed({
      get: () => store.state.advancedFilters.termsString,
    });
    const minBudget = computed({
      get: () =>
        store.state.advancedSearch.minBudget != null
          ? store.state.advancedSearch.minBudget
          : Number(sessionStorage.getItem("minBudget")),
    });

    const maxBudget = computed({
      get: () =>
        store.state.advancedSearch.maxBudget != null
          ? store.state.advancedSearch.maxBudget
          : Number(sessionStorage.getItem("maxBudget")),
    });

    const scopesString = computed({
      get: () => store.state.advancedFilters.scopesString,
    });

    const startDate = computed({
      get: () =>
        store.state.advancedSearch.startDate != null &&
        store.state.advancedSearch.startDate != ""
          ? store.state.advancedSearch.startDate
          : JSON.parse(sessionStorage.getItem("startDate")),
    });

    const endDate = computed({
      get: () =>
        store.state.advancedSearch.endDate != null &&
        store.state.advancedSearch.endDate != ""
          ? store.state.advancedSearch.endDate
          : JSON.parse(sessionStorage.getItem("endDate")),
    });
    const orgsIncludeString = computed({
      get: () =>
        store.state.advancedSearch.organisationsIncludeString != ""
          ? store.state.advancedSearch.organisationsIncludeString
          : sessionStorage.getItem("organisationsIncludeString"),
    });

    const orgsExcludeString = computed({
      get: () =>
        store.state.advancedSearch.organisationsExcludeString != ""
          ? store.state.advancedSearch.organisationsExcludeString
          : sessionStorage.getItem("organisationsExcludeString"),
    });

    const activeFiltersCount = computed({
      get: () => store.state.advancedSearch.activeFiltersCount,
    });

    const enableSave = () => {
      store.commit("user/enableSave");
    };

    const enableSaveScheme = () => {
      if (numberOfSelectedResults.value > 0) {
        UserService.getFolders();
        store.commit("user/enableSaveScheme");
      } else showWarning.value = true;
    };

    const showSave = computed({
      get: () => store.state.user.showSave,
    });

    const receiveAlertForSavedSearch = computed({
      get: () => store.state.user.receiveAlertForSavedSearch,
      set: (val) => {
        store.commit("user/setReceiveAlertForSavedSearch", val);

        UserService.updateFolderItemAlert(savedSearchFolderItemId.value, val);
      },
    });

    const receiveAlertForSavedItems = computed({
      get: () => store.state.user.receiveAlertForSavedItems,
      set: (val) => {
        store.commit("user/setReceiveAlertForSavedItems", val);

        UserService.updateFolderItemsAlert(
          savedSchemesFolderItemsIds.value,
          val
        );
      },
    });

    const savedSearchFolderItemId = computed({
      get: () => store.state.user.savedSearchFolderItemId,
    });

    const savedSchemesFolderItemsIds = computed({
      get: () => store.state.user.savedSchemesFolderItemsIds,
    });

    const goToPage = async (currentPage) => {
      store.commit("advancedSearch/updateCurrentPage", currentPage);
      store.commit("advancedFilters/createTermsString");
      store.commit("advancedFilters/createSMEString");
      store.commit("advancedFilters/createScopesString");
      await GrantsService.getResulstsByPage(
        locale,
        currentPage - 1,
        searchText,
        ref(sessionStorage.getItem('statusesString')),
        audiences,
        locations,
        //This is just hidden for now, don't remove it:
        //industries,
        purposes,
        schemeTypes,
        projectTypes,
        smeString,
        applicationTermsString,
        minBudget,
        maxBudget,
        scopesString,
        orgsIncludeString,
        orgsExcludeString,
        startDate,
        endDate,
        selectedSort
      );
      window.scrollTo(0, 0);
    };

    const currentPage = computed({
      get: () => store.state.advancedSearch.currentPage,
      set: (value) => store.commit("advancedSearch/updateCurrentPage", value),
    });

    const scTimer = ref(0);
    const scY = ref(0);
    const handleScroll = () => {
      if (scTimer.value) return;
      scTimer.value = setTimeout(() => {
        scY.value = window.scrollY;
        clearTimeout(scTimer.value);
        scTimer.value = 0;
      }, 100);
    };

    const toTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const selectAllFromThisPage = () => {
      store.commit("grantItems/selectAllFromPage");
    };

    const deslectAllFromThisPage = () => {
      store.commit("grantItems/deselectAllFromPage");
    };

    const numberOfSelectedResults = computed({
      get: () => store.state.grantItems.selectedResultsCount,
    });

    const resultsSelectedLabel = ref(
      "Regelingen selecteren " +
        (numberOfSelectedResults.value > 0
          ? "(" + numberOfSelectedResults.value + ")"
          : "")
    );

    watch(numberOfSelectedResults, () => {
      resultsSelectedLabel.value =
        "Regelingen selecteren " +
        (numberOfSelectedResults.value > 0
          ? "(" + numberOfSelectedResults.value + ")"
          : "");
    });

    const sharePage = () => {
      if (numberOfSelectedResults.value > 0) {
        store.commit("grantItems/enableShareGrantsPage");
      } else showWarning.value = true;
    };

    const downloadPage = () => {
      if (numberOfSelectedResults.value > 0) {
        store.commit("grantItems/enableDownloadSchemesPage");
      } else showWarning.value = true;
    };

    const isPageLoading = computed({
      get: () => store.state.grantItems.isSearchPageLoading,
    });

    const isSideBarLoading = computed({
      get: () => store.state.grantItems.isSideBarLoading,
    });

    const numberOfResultsOnPage = computed({
      get: () => store.state.grantItems.grants.length,
    });

    const sideBar = ref(null);

    const resetSearch = (isClear) => {
      sideBar.value.resetSearch(isClear);
    };

    const activeFiltersLabel = ref(
      "Actieve filters: " + activeFiltersCount.value
    );

    watch(activeFiltersCount, () => {
      activeFiltersLabel.value = "Actieve filters: " + activeFiltersCount.value;
    });

    const refreshTrees = () => {
      sideBar.value.refreshTrees();
    };

    const applyProfileFilters = () => {
      store.commit(
        "advancedSearch/updateSelectedAudiences",
        store.state.user.favoriteAudiencesIdsArray
      );
      //This is just hidden for now, don't remove it:
      /*
      store.commit(
        'advancedSearch/updateSelectedIndustries',
        store.state.user.favoriteIndustriesIdsArray
      );
      */
      store.commit(
        "advancedSearch/updateSelectedPurposes",
        store.state.user.favoritePurposesIdsArray
      );
      store.commit(
        "advancedSearch/updateSelectedLocations",
        store.state.user.selectedFavoriteLocations
      );

      refreshTrees();
    };

    const showSavedInfoDialog = computed({
      get: () => store.state.user.showSavedInfoDialog,
    });

    const showSavedSearchInfoDialog = computed({
      get: () => store.state.user.showSavedSearchInfoDialog,
    });

    const disableShowSavedInfoDialog = () => {
      store.commit("user/disableShowSavedInfoDialog");
    };

    const disableShowSavedSearchInfoDialog = () => {
      store.commit("user/disableShowSavedSearchInfoDialog");
    };

    const showHiddenInfoDialog = computed({
      get: () => store.state.user.showHiddenInfoDialog,
    });

    const hideHiddenItemsDialog = computed({
      get: () => store.state.user.hideHiddenItemsDialog,
      set: (value) => {
        store.commit("user/updateHideHiddenItemsDialog", value);
        UserService.setHideHiddenItemsDialog(value);
      },
    });

    const disableShowHiddenInfoDialog = () => {
      store.commit("user/disableShowHiddenInfoDialog");
    };

    const isProfileSearch = computed({
      get: () => store.state.user.isProfileSearch,
    });

    const areFiltersChanged = computed({
      get: () => store.state.advancedSearch.areFiltersChanged,
    });

    const steps = [
      {
        target: "#v-step-0", // We're using document.querySelector() under the hood
        offset: -3000,

        content: t('tour.use_the_filters_advice'),
      },
      {
        target: "#v-step-1",
        offset: -600,

        content: t('tour.enter_keywords'),
      },
      {
        target: ".v-step-2",
        offset: -800,

        content:  t('tour.search_results_explanation'),
      },
      {
        target: "#v-step-3", // We're using document.querySelector() under the hood
        offset: -800,

        content: t('tour.select_multiple_items'),

        params: {
          placement: "top",
        },
      },
      {
        target: "#v-step-4",
        offset: -800,

        content:
          t('tour.save_selected_items'),
      },
      {
        target: "#v-step-5",
        offset: -800,

        content:
          t('tour.share_selected_items'),
      },
      {
        target: "#v-step-6",
        offset: -800,

        content:
          t('tour.download_selected_items'),
      },
      {
        target: "#v-step-7",
        offset: -800,

        content:
          t('tour.save_scheme'),
      },

      {
        target: "#v-step-8",
        offset: -800,

        content: t('tour.hide_scheme'),
      },
      {
        target: "#v-step-9",
        offset: -800,

        content: t('tour.share_scheme')
      },
      {
        target: "#v-step-10",
        offset: -800,

        content:
          t('tour.download_scheme'),
      },
      {
        target: "#v-step-11",
        offset: -800,

        content:
          t('tour.apply_profile_filters'),
      },
      {
        target: "#v-step-12",
        offset: -800,

        content:
          t('tour.save_search_and_set_notifications'),
      },
      {
        target: "#v-step-13",
        offset: -800,

        content:
          t('tour.profile_section'),
      },
    ];

    const tour = ref(null);
    const startTour = () => {
      tour.value.customOptions.labels.buttonNext = t('tour.next');
      tour.value.customOptions.labels.buttonPrevious = t('tour.previous');
      tour.value.customOptions.labels.buttonStop = t('tour.finished');
      tour.value.customOptions.labels.buttonSkip = t('tour.end_tour');

      tour.value.callbacks.onStart = () => {
        store.commit("user/updateIsTourActive", true);
      };
      tour.value.callbacks.onSkip = () => {
        store.commit("user/updateIsTourActive", false);
      };
      tour.value.callbacks.onStop = () => {
        store.commit("user/updateIsTourActive", false);
      };
      tour.value.callbacks.onFinish = () => {
        store.commit("user/updateIsTourActive", false);
      };
      tour.value.start();
    };

    const isTourActive = computed({
      get: () => store.state.user.isTourActive,
    });

    const dummyGrant = {
      id: "100515",
      idoxKey: "S10706",
      identifier: null,
      itemType: 0,
      smeType: "NotSME",
      score: 2,
      name: "Subsidieregeling praktijkleren",
      introduction:
        "Het doel van de Subsidieregeling praktijkleren (PRAKTIJKLEREN) is het stimuleren van werkgevers om praktijkleerplaatsen en werkleerplaatsen aan te bieden.",
      body: "<p>Het doel van het A&amp;O Fonds Grafimediabranche (AOFG) is het bevorderen van opleidingen en de arbeidsmarkt in de grafimediabranche.\n<br />\n<br />Werkgevers en werknemers die lid zijn van het A&amp;O Fonds Grafimediabranche komen in aanmerking voor subsidie.\n<br />\n<br />Het fonds beschikt over de volgende subsidieregelingen:</p>\n<ul>\n    <li>Stimuleringsregeling BBL;</li>\n    <li>Subsidieregeling opleidingen machineleveranciers;</li>\n    <li>Subsidieregeling printmedia opleidingen;</li>\n    <li>Subsidieregeling uitzendkrachten;</li>\n    <li>Subsidieregeling voor begeleidingstaken praktijkopleiders;</li>\n    <li>Subsidieregeling voor financieel adviesgesprek;</li>\n    <li>Tijdelijke subsidieregeling van-werk-naar werk trajecten/mobiliteitsprojecten.</li>\n</ul>\n<p>\n<br />De voorwaarden en de hoogte van de bijdrage verschillen per regeling.\n<br />\n<br />Aanvragen kunnen worden ingediend bij het A&amp;O Fonds Grafimediabranche.</p>",
      criteria: null,
      accronym: "AOFG",
      organisations: [
        {
          id: "156115",
          value: "Ministerie van Onderwijs, Cultuur en Wetenschap (OCW)",
        },
      ],
      status: "Aangekondigd",
      publicationStatus: "Live",
      scope: "Undefined",
      applicationTerm: "Undefined",
      link: "",
      periodTitle: null,
      periodDescription: null,
      calls: [],
      minAmount: 3015.0,
      maxAmount: "Onbepaald",
      createdDate: "22-02-06T00:00:00",
      updatedDate: "2022-02-17T13:53:26.7855733",
      currentDeadline: null,
      schemeStart: null,
      schemeEnd: null,
      callStart: null,
      callEnd: null,
      currentDeadlineHeaderText: "Openstelling",
      currentDeadlineText: "Onbepaald",
      callCount: 0,
    };

    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    const isContrast = computed({
      get: () => store.state.user.isSelectedThemeContrast,
    });

    const isDisplayDownloadMessage = computed({
      get: () => store.state.grantItems.isDisplayDownloadMessage,
    });

    const selectAllSchemes = ref(false);

    const selectAll = () => {
      selectAllSchemes.value
        ? selectAllFromThisPage()
        : deslectAllFromThisPage();
    };

    const width = window.width;
    const seamless = ref(false);
    const drawerLeft = ref(false);
    return {
      t,
      locale,
      organisationSchemeSearch,
      seamless,
      drawerLeft,
      width,
      selectAllSchemes,
      selectAll,
      isDisplayDownloadMessage,
      isContrast,
      isVind,
      dummyGrant,
      isTourActive,
      steps,
      tour,
      startTour,
      areFiltersChanged,
      isApplyingFilters,
      isProfileSearch,
      showSavedInfoDialog,
      showSavedSearchInfoDialog,
      showHiddenInfoDialog,
      hideHiddenItemsDialog,
      disableShowSavedInfoDialog,
      disableShowHiddenInfoDialog,
      disableShowSavedSearchInfoDialog,
      sideBar,
      activeFiltersLabel,
      resetSearch,
      isSideBarLoading,
      downloadPage,
      numberOfResultsOnPage,
      isPageLoading,
      sharePage,
      numberOfSelectedResults,
      resultsSelectedLabel,
      deslectAllFromThisPage,
      selectAllFromThisPage,
      activeFiltersCount,
      handleScroll,
      toTop,
      scTimer,
      scY,
      orgsIncludeString,
      orgsExcludeString,
      applicationTermsString,
      smeString,
      grants,
      shortDesc,
      pagesCount,
      resultsCount,
      router,
      minBudget,
      maxBudget,
      startDate,
      endDate,
      searchText,
      currentPage,
      logStatus,
      maxPages,
      schemeTypes,
      projectTypes,
      purposes,
      scopesString,
      //This is just hidden for now, don't remove it:
      //industries,
      audiences,
      locations,
      goToPage,
      enableSave,
      enableSaveScheme,
      showSave,
      selectedSort,
      sortOptions,
      showWarning,
      applyProfileFilters,
      refreshTrees,
      receiveAlertForSavedSearch,
      savedSearchFolderItemId,
      receiveAlertForSavedItems,
      savedSchemesFolderItemsIds,
      maxScoreOnCurrentPage,
      organisationsSchemes,
      maxScore,
      getSchemeMatchingPercentage,
      computed: {
        ...mapState({
          showAdvancedSearch: (state) =>
            state.advancedSearch.showAdvancedSearch,
        }),
        ...mapGetters("advancedSearch", {
          showAdvancedSearch: "showAdvancedSearch",
        }),
      },
    };
  },
};
</script>
