<template>
  <footer>
    <div class="column footer" :class="isVind ? 'footer-background' : 'footer-background-portals'">
      <div class="row main-row" v-if="isVind && route.path != '/public/news-details'">
        <div class="logo-header">
          <a href="/search-grants">
            <div class="logo" />
          </a>
        </div>
        <div class="footer-top-section">
          <div class="column">
            <span class="items-heading bold-font">{{ t('footer.sitemap') }}:</span>
            <div class="footer-top-links-l">
              <a class="info-top row" href="https://www.vindsubsidies.nl/contact/" target="_blank">{{
      t('footer.contact') }}</a>
              <a class="info-top row" href="https://www.vindsubsidies.nl/over-ons/" target="_blank">{{
      t('footer.about_us') }}</a>
              <a class="info-top row" href="https://www.vindsubsidies.nl/algemene-voorwaarden/" target="_blank">{{
      t('footer.terms_and_conditions') }}</a>
              <a class="info-top row" href="https://www.vindsubsidies.nl/faq-database/" target="_blank">
                {{ t('footer.faq_database') }}
              </a>
            </div>
          </div>

          <div class="column">
            <span class="items-heading bold-font">{{ t('footer.contact') }}:</span>

            <div class="footer-top-links-m">
              <span class="info-top">088 - 2020 401</span>
            </div>
            <div class="footer-top-links-m">
              <a class="info-top" href="mailto:info@vindsubsidies.nl">
                <span class="info-top">info@vindsubsidies.nl</span>
              </a>
            </div>
          </div>
          <div class="column">
            <span class="items-heading bold-font">{{ t('footer.follow_us') }}:</span>
            <div class="row footer-top-links-r">
              <a class="info-links" :aria-label="t('footer.twitter_link')" href="https://twitter.com/vindsubsidies"
                target="_blank">
                <i class="lab la-2x la-twitter social-icon"></i>
              </a>
              <a class="info-links" :aria-label="t('footer.facebook_link')"
                href="https://www.facebook.com/vindsubsidies/" target="_blank">
                <i class="lab la-2x la-facebook-f social-icon"></i>
              </a>
              <a class="info-links" :aria-label="t('footer.linkedIn_link')"
                href="https://www.linkedin.com/company/vindsubsidies-nl/mycompany/" target="_blank">
                <i class="lab la-2x la-linkedin-in social-icon"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row portals-footer" v-else>
        <div v-if="customer.phoneNumber != null || customer.emailAddress != null" class="column portal-contact-info">
          <span class="items-heading-portals q-mb-sm bold-font">{{ t('footer.contact') }}:</span>

          <div v-if="customer.phoneNumber != null" class="flex">
            <span class="items-heading-info">{{ customer.phoneNumber }}</span>
          </div>
          <div v-if="customer.emailAddress != null" class="footer-top-links-m">
            <a v-bind:href="`mailto:${customer.emailAddress}`">
              <span class="items-heading-info">{{ customer.emailAddress }}</span>
            </a>
          </div>
        </div>
        <div class="column">
          <span v-if="customer.twitterUrl != null ||
      customer.facebookUrl != null ||
      customer.facebookUrl != null ||
      customer.linkedinUrl != null
      " class="items-heading-portals bold-font">{{ t('footer.follow_us') }}:</span>
          <div class="row footer-top-links-r">
            <a v-if="customer.twitterUrl != null" class="info-links" :aria-label="t('footer.twitter_link')"
              :href="customer.twitterUrl" target="_blank">
              <i class="lab la-twitter social-icon-portals"></i>
            </a>
            <a v-if="customer.facebookUrl != null" class="info-links" :aria-label="t('footer.facebook_link')"
              :href="customer.facebookUrl" target="_blank">
              <i class="lab la-facebook-f social-icon-portals"></i>
            </a>
            <a v-if="customer.linkedinUrl != null" class="info-links" :aria-label="t('footer.linkedIn_link')"
              :href="customer.linkedinUrl" target="_blank">
              <i class="lab la-linkedin-in social-icon-portals"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="row bottom-footer">
        <span class="bottom-text">
          {{ t('footer.terms_and_conditions') }} | {{ t('footer.privacy_and_cookie_policy') }} | {{
      t('footer.disclaimer') }} | © 2023
          vindsubsidies.nl | {{ t('footer.design_by') }}</span>
      </div>
    </div>
  </footer>
</template>

<script>
import store from '../store/index.js';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import authService from '../services/auth.service';

export default {
  setup() {
    onMounted(async () => {
      authService.getEnvironment();
      await authService.getPortalInfo();
    });

    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    const customer = computed({
      get: () => store.state.auth.customer,
    });
    const route = useRoute();
    const isLoggedIn = computed({
      get: () => store.state.auth.isLoggedIn,
    });

    return {
      route,
      isVind,
      isLoggedIn,
      customer,
    };
  },
};
</script>
