<template>
  <header>
    <div class="nav-container" v-if="route.path != '/nieuwsbericht' &&
      route.path != '/onze-bevindingen-uitschrijven' &&
      route.path != '/onze-bevindingen/nieuwsbrief'
      ">
      <div v-bind:class="{ show: isExpanded }">
        <div class="menu-buttons" :class="!isVind ? 'portalmenu-buttons' : ''">
          <div class="lang-switcher-container">
            <LanguageSwitcher v-if="isVind" />
          </div>
          <Slide right class="burger-menu" :closeOnNavigation="true">
            <router-link :to="{ name: 'SearchGrants' }" v-if="isLoggedIn">
              <q-btn :label="t('navbar.search_schemes')" class="burgermenu-button bold-font" flat no-caps>
              </q-btn>
            </router-link>

            <router-link :to="{ name: 'News' }" v-if="isLoggedIn">
              <q-btn :label="t('navbar.search_news')" class="burgermenu-button bold-font" flat no-caps>
              </q-btn>
            </router-link>

            <router-link :to="{ name: 'HomePage' }" v-if="isLoggedIn && isVind">
              <q-btn :label="t('navbar.dashboard')" class="burgermenu-button bold-font" flat no-caps>
              </q-btn>
            </router-link>

            <router-link :to="{ name: 'UserProfile', params: { tab: 'myBasicDetails' } }" v-if="isLoggedIn && !isSKS"
              @click="tabValue('myBasicDetails')">
              <q-btn :label="t('navbar.details')" class="burgermenu-button bold-font" flat no-caps>
              </q-btn>
            </router-link>

            <router-link :to="{ name: 'UserProfile', params: { tab: 'newsletter' } }" v-if="isLoggedIn && isVind"
              @click="tabValue('newsletter')">
              <q-btn :label="t('navbar.profile_and_newsletter')" class="burgermenu-button bold-font" flat no-caps>
              </q-btn>
            </router-link>

            <router-link :to="{
      name: 'UserProfile',
      params: { tab: 'savedItemsSchemes' },
    }" v-if="isLoggedIn && isVind" @click="tabValue('savedItemsSchemes')">
              <q-btn :label="t('navbar.saved_items')" class="burgermenu-button bold-font" flat no-caps>
              </q-btn>
            </router-link>
            <router-link :to="{ name: 'UserProfile', params: { tab: 'hiddenSchemes' } }" v-if="isLoggedIn && isVind"
              @click="tabValue('hiddenSchemes')">
              <q-btn :label="t('navbar.hidden_schemes')" class="burgermenu-button bold-font" flat no-caps>
              </q-btn>
            </router-link>

            <router-link :to="{ name: 'UserProfile', params: { tab: 'userManagement' } }" v-if="isLoggedIn && isVind"
              @click="tabValue('customersBasicDetails')">
              <q-btn :label="t('navbar.account_management')" class="burgermenu-button bold-font" flat no-caps>
              </q-btn>
            </router-link>

            <router-link :to="{ name: 'Login' }" v-if="isLoggedIn">
              <q-btn class="burgermenu-button bold-font" flat no-caps @click="logout">
                <i class="fa-solid fa-right-from-bracket q-mr-sm"></i>{{ t('navbar.log_out') }}
              </q-btn>
            </router-link>
          </Slide>

          <div class="largescreen-buttons">
            <!--   <div class="logo-header">
            <div class="logo" />
          </div> -->
            <div v-if="isVind" class="logo-header">
              <a href="/search-grants">
                <div class="logo" />
              </a>
            </div>
            <div v-else class="logo-header">
              <div aria-label="Naar startpagina" @click="goToBaseUrl()" style="cursor: pointer;">
                <div class="logo" />
              </div>
            </div>
            <div class="all-menu-buttons">
              <div class="leftmenu-buttons">
                <router-link :aria-label="t('navbar.go_to_the_grant_search_page')" :to="isVind
      ? { name: 'SearchGrants' }
      : { name: 'PortalSearchGrants' }
      " class="navmenu-link">
                  <q-btn flat color="white" :label="isVind ? t('navbar.search_schemes') : t('navbar.my_overview')"
                    no-caps v-if="isLoggedIn" class="navmenu-link q-ml-sm" :class="route.name == 'SearchGrants' ||
      route.name == 'PortalSearchGrants'
      ? 'current-route'
      : ''
      ">
                  </q-btn>
                </router-link>
                <router-link :aria-label="t('navbar.go_to_news_page')" :to="{ name: 'News' }" class="navmenu-link">
                  <q-btn class="navmenu-link q-ml-sm" flat color="white" no-caps
                    :label="isVind ? t('navbar.search_news') : t('search_news.news')" v-if="isLoggedIn"
                    :class="route.name == 'News' ? 'current-route' : ''">
                    <q-badge v-if="displayNumberOfNewsResults" class="results-badge" :label="resultsCount">{{
      resultCount }}</q-badge>
                  </q-btn>
                </router-link>
                <router-link :aria-label="t('navbar.go_to_home_page')" :to="{ name: 'HomePage' }" class="navmenu-link">
                  <q-btn flat color="white" :label="t('navbar.dashboard')" no-caps v-if="isLoggedIn && isVind"
                    class="navmenu-link q-ml-sm" :class="route.name == 'HomePage' ? 'current-route' : ''">
                  </q-btn>
                </router-link>

                <router-link v-if="hasObRole" :to="{ name: 'OnzeExportPage', params: { tab: 'export' } }"
                  class="navmenu-link">
                  <q-btn flat color="white" label="BeVINDingen " no-caps v-if="isLoggedIn" class="navmenu-link q-ml-sm"
                    :class="route.name == 'OnzeExportPage' ? 'current-route' : ''
      ">
                  </q-btn>
                </router-link>
                <router-link :aria-label="t('navbar.mailing')" :to="{ name: 'Mailing' }" class="navmenu-link">
                  <q-btn flat color="white" :label="t('navbar.mailing')" no-caps v-if="isLoggedIn && hasMailingRole"
                    class="navmenu-link q-ml-sm" :class="route.name == 'Mailing' ? 'current-route' : ''">
                  </q-btn>
                </router-link>
              </div>
              <div class="right-menu-buttons row">
                <div class="navmenu-link q-mr-sm">
                  <LanguageSwitcher v-if="isVind" />
                </div>
                <div class="q-ml-sm">
                  <q-btn-dropdown flat color="white" v-if="isLoggedIn && !isAutoLog" icon="fa-solid fa-circle-user"
                    class="navmenu-link" id="v-step-13" :aria-label="t('navbar.menu_buttons')">
                    <q-list class="nav-list-largescreen">
                      <router-link :to="{
      name: 'UserProfile',
      params: { tab: 'myBasicDetails' },
    }" class="navmenu-link" style="text-decoration: none; color: black" @click="tabValue('myBasicDetails')">
                        <q-item clickable v-close-popup v-if="!isSKS">
                          <q-item-section> {{ t('navbar.details') }} </q-item-section>
                        </q-item>
                      </router-link>

                      <router-link v-if="isVind" :to="{
      name: 'UserProfile',
      params: { tab: 'newsletter' },
    }" class="navmenu-link" style="text-decoration: none; color: black" @click="tabValue('newsletter')">
                        <q-item clickable v-close-popup>
                          <q-item-section>{{ t('navbar.profile_settings') }} </q-item-section>
                        </q-item>
                      </router-link>

                      <router-link v-if="isVind" :to="{
      name: 'UserProfile',
      params: { tab: 'nieuwsbrief' },
    }" class="navmenu-link" style="text-decoration: none; color: black" @click="tabValue('nieuwsbrief')">
                        <q-item clickable v-close-popup>
                          <q-item-section>{{ t('navbar.newsletter_settings') }}
                          </q-item-section>
                        </q-item>
                      </router-link>

                      <router-link :to="{
      name: 'UserProfile',
      params: { tab: 'savedItemsSchemes' },
    }" class="navmenu-link" style="text-decoration: none; color: black" @click="tabValue('savedItemsSchemes')">
                        <q-item clickable v-close-popup>
                          <q-item-section v-if="isVind">
                            {{ t('navbar.saved_items') }}
                          </q-item-section>
                          <q-item-section v-else>{{ t('navbar.favorites') }}</q-item-section>
                        </q-item>
                      </router-link>

                      <router-link v-if="isVind" :to="{
      name: 'UserProfile',
      params: { tab: 'hiddenSchemes' },
    }" class="navmenu-link" style="text-decoration: none; color: black" @click="tabValue('hiddenSchemes')">
                        <q-item clickable v-close-popup>
                          <q-item-section> {{ t('navbar.hidden_schemes') }} </q-item-section>
                        </q-item>
                      </router-link>

                      <router-link v-if="showAdminPanel && isVind" :to="{
      name: 'UserProfile',
      params: { tab: 'accountgegevens' },
    }" @click="tabValue('accountgegevens')" class="navmenu-link" style="text-decoration: none; color: black">
                        <q-item clickable v-close-popup>
                          <q-item-section> {{ t('navbar.account_management') }} </q-item-section>
                        </q-item>
                      </router-link>

                      <router-link flat color="white" :to="{ name: 'Login' }" class="navmenu-link"
                        style="text-decoration: none; color: black">
                        <q-item clickable v-close-popup>
                          <q-item-section @click="logout">
                            {{ t('navbar.log_out') }}
                          </q-item-section>
                        </q-item>
                      </router-link>
                    </q-list>
                  </q-btn-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import store from '../store/index.js';
import { Slide } from 'vue3-burger-menu';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import LanguageSwitcher from '../views/LanguageSwitcher.vue';
export default {
  name: 'NavMenu',
  components: {
    Slide,
    LanguageSwitcher,
  },
  setup() {
    let user = JSON.parse(localStorage.getItem('user'));

    const userRoles = computed({
      get: () => store.state.user.userRolesList,
    });

    const hasObRole =
      user && user.roles
        ? user.roles.some((element) => {
          if (element.name == 'Vindsubsidies user') {
            return true;
          }
          return false;
        })
        : false;

    const hasMailingRole =
      user && user.roles
        ? user.roles.some((element) => {
          if (element.name == 'Investeringen') {
            return true;
          }
          return false;
        })
        : false;

    const route = useRoute();
    const router = useRouter();

    const logout = () => {
      store.dispatch('auth/logout');
      router.push({
        name: 'Login',
      });
    };

    const collapse = () => {
      this.isExpanded = false;
    };

    const toggle = () => {
      this.isExpanded = !this.isExpanded;
    };

    const isLoggedIn = computed({
      get: () => store.state.auth.isLoggedIn,
    });

    const showAdminPanel = computed({
      get: () => store.state.auth.showAdminPanel,
    });

    const isExpanded = ref(false);

    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    const isAutoLog = computed({
      get: () => store.state.auth.env === 50 || store.state.auth.env === 60,
    });

    const resultCount = computed({
      get: () => store.state.newsSearch.resultsCount,
    });

    const displayNumberOfNewsResults = computed({
      get: () => store.state.advancedSearch.displayNumberOfNewsResults,
    });

    const setThemeToContrast = () => {
      store.commit('user/setIsSelectedThemeContrast', true);
      localStorage.setItem('themeContrast', true);
    };

    const setNormalTheme = () => {
      store.commit('user/setIsSelectedThemeContrast', false);
      localStorage.setItem('themeContrast', false);
    };

    const toggleTheme = () => {
      if (isContrastTheme.value == false || isContrastTheme.value == 'false') {
        store.commit('user/setIsSelectedThemeContrast', true);
        localStorage.setItem('themeContrast', true);
      } else {
        store.commit('user/setIsSelectedThemeContrast', false);
        localStorage.setItem('themeContrast', false);
      }
    };

    const isContrastTheme = computed({
      get: () => store.state.user.isSelectedThemeContrast,
    });
    const tabValue = (tabName) => {
      if (tabName == 'savedItemsSchemes') {
        store.commit('user/updateIsClickedSavedItems', true);
        store.commit('user/updateIsClickedUserManagement', false);
        store.commit('user/updateIsClickedNewsletter', false);
        store.commit('user/updateIsClickedMenuItemSchemes', true);
        store.commit('user/updateIsClickedMenuItemCustomersBasic', false);
        store.commit('user/updateIsClickedMenuItemAudiences', false);
      }
      if (tabName == 'accountgegevens') {
        store.commit('user/updateIsClickedSavedItems', false);
        store.commit('user/updateIsClickedUserManagement', true);
        store.commit('user/updateIsClickedNewsletter', false);
        store.commit('user/updateIsClickedMenuItemCustomersBasic', true);
        store.commit('user/updateIsClickedMenuItemAudiences', false);
        store.commit('user/updateIsClickedMenuItemSchemes', false);
      }
      if (tabName == 'newsletter') {
        store.commit('user/updateIsClickedMenuItemAudiences', true);
        store.commit('user/updateIsClickedSavedItems', false);
        store.commit('user/updateIsClickedNewsletter', true);
        store.commit('user/updateIsClickedUserManagement', false);
        store.commit('user/updateIsClickedMenuItemSchemes', false);
        store.commit('user/updateIsClickedMenuItemCustomersBasic', false);
      }
      if (
        tabName == 'hiddenSchemes' ||
        tabName == 'nieuwsbrief' ||
        tabName == 'myBasicDetails'
      ) {
        store.commit('user/updateIsClickedSavedItems', false);
        store.commit('user/updateIsClickedUserManagement', false);
        store.commit('user/updateIsClickedNewsletter', false);
        store.commit('user/updateIsClickedMenuItemSchemes', false);
        store.commit('user/updateIsClickedMenuItemCustomersBasic', false);
        store.commit('user/updateIsClickedMenuItemAudiences', false);
        store.commit('user/updateIsClickedMenuItemPurposes', false);
        store.commit('user/updateIsClickedMenuItemLocations', false);
      }
    };

    const customer = computed({
      get: () => store.state.auth.customer,
    });


    const goToBaseUrl = () => {
      const logoUrl = customer.value.baseUrl + '/search-grants';

      window.location.href = logoUrl;
    };

    const isSKS = computed({
      get: () => store.state.auth.env === 30,
    });

    return {
      isSKS,
      hasMailingRole,
      goToBaseUrl,
      customer,
      user,
      userRoles,
      hasObRole,
      router,
      route,
      toggleTheme,
      isContrastTheme,
      tabValue,
      setNormalTheme,
      setThemeToContrast,
      displayNumberOfNewsResults,
      resultCount,
      logout,
      isLoggedIn,
      collapse,
      toggle,
      isExpanded,
      showAdminPanel,
      isVind,
      isAutoLog,
    };
  },
};
</script>
