<template>
  <q-checkbox
    :key="schemeId"
    v-model="isSelected"
    id="v-step-3"
    @update:model-value="selectScheme(schemeId)"
    class="grant-select-check"
    color="#0a108f"
    dense
  />
</template>
<script>
import { ref, onMounted, watch } from 'vue';
import store from '../store/index';
export default {
  props: {
    id: String,
    allSelected: Array,
  },
  setup(props) {
    const selectedResults = ref(props.allSelected);
    watch(props.allSelected, () => {
      isSelected.value =
        props.allSelected.filter((r) => r.schemeId == props.id).length > 0;
    });

    const schemeId = ref(props.id);

    const isSelected = ref(false);

    onMounted(() => {
      isSelected.value =
        selectedResults.value.filter((r) => r.schemeId == props.id).length > 0;
    });

    const selectScheme = (id) => {
      store.commit('grantItems/addSelected', id);
    };

    const updateSelected = () => {
      isSelected.value =
        selectedResults.value.filter((r) => r.schemeId == props.id).length > 0;
    };

    return {
      updateSelected,
      schemeId,
      selectScheme,
      isSelected,
    };
  },
};
</script>
